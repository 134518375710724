<template>
  <hs-popup
    :show-hide="false"
    aria-labelledby="unknown-page-titile"
    aria-describedby="unknown-page-desc"
    class="unknown-page"
  >
    <div class="unknown-page__icon"></div>
    <h1 class="hs-popup__title" id="unknown-page-titile">
      Sorry, something went wrong. It’s not you, it’s us.
    </h1>
    <div class="hs-popup__description" id="unknown-page-desc">
      <p class="unknown-page__text">
        Seems like we have a trouble loading this page. Please try again later or go back to
        previous page
      </p>
    </div>
    <div class="hs-popup__footer">
      <hs-button :route="true" :link="mainPageLink" size="sm">Go to the main page</hs-button>
    </div>
  </hs-popup>
</template>

<script>
export default {
  computed: {
    mainPageLink() {
      return {name: 'MyDevices', query: {...this.$route.query}};
    },
  },
};
</script>

<style lang="scss" scoped>
.unknown-page {
  &__icon {
    width: 100px;
    height: 100px;
    margin: 15px auto;
    background-image: url('~@/assets/images/sad-icon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    @include mf($gridMDBreakpoint) {
      width: 125px;
      height: 125px;
      margin: 35px auto 42px;
    }
  }
}
</style>
<style lang="scss">
.unknown-page {
  &__text {
    max-width: 595px;
    font-size: 22px;
  }
  .hs-popup {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include mf($gridMDBreakpoint) {
        flex-basis: 973px;
        max-width: 973px;
      }
    }
    &__title {
      margin-right: 0;
    }
    &__footer {
      align-self: stretch;
      *:only-child {
        margin-right: auto;
        margin-left: auto;
      }
    }
    &__overlay {
      background-color: #eceae6;
    }
  }
}
</style>
